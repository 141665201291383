import React from 'react';
import { PathService } from '../services';
import { TagsViewContext, TemplateProps } from '../types';
import { TagsView } from '../views';

const Tags: React.FunctionComponent<TemplateProps<TagsViewContext>> = ({
  location,
  pageContext,
}) => {
  PathService.set(location.pathname);
  return (
    <TagsView title={pageContext.title} items={JSON.parse(pageContext.items)} />
  );
};

export default Tags;
